import type { Reroute } from "@sveltejs/kit";
import { getSubdomainInfo } from "./utils/urls";

// get all the top-level community routes based on the +page files
const files = import.meta.glob("/src/routes/[community]/**/+page.svelte");
const communityRoutes = new Set(
  Object.keys(files).map(
    (
      file // remove everything except the top-level route/folder name
    ) => file.replace("/src/routes/[community]/", "").replace("/+page.svelte", "").split("/")[0]
  )
);

/** Reroute all community routes to have a community slug param.
 *
 * Custom domain routes don't have a community slug param, so we add it here,
 * this makes accessing the community param much easier in the rest of the app.
 */
export const reroute: Reroute = ({ url }) => {
  console.log("reroute", url.pathname);
  let pathname = url.pathname;
  if (url.pathname.startsWith("/help")) {
    pathname = pathname.replace("/help", "");
  }

  console.log("split", pathname);
  console.log(url);

  // route as normal for any non-community pages (auth, login, etc)
  if (!communityRoutes.has(pathname.split("/")[1])) return;

  // check for custom domain and prepend to url
  const { communitySlug, usingSubdomain } = getSubdomainInfo(url.hostname);
  console.log("usingSubdomain - communitySlug", usingSubdomain, communitySlug);
  if (usingSubdomain) {
    // add community slug param
    return `/${communitySlug}${pathname}`;
  }

  // otherwise, route as normal
};
