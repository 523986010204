import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { API_URL } from "api";

if (import.meta.env.VITE_BUILD_ENV) {
  Sentry.init({
    dsn: "https://ecd8b4cada564b21995f3d7959056703@o1045488.ingest.sentry.io/6020896",
    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      new Replay(),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [API_URL, /^\//],
      }),
    ],
    environment: import.meta.env.VITE_BUILD_ENV,
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
