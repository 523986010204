import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0];

export const dictionary = {
		"/": [13],
		"/auth": [51],
		"/error": [52],
		"/healthcheck/diagnose": [53],
		"/(login)/login": [14,[2]],
		"/(login)/sign-up": [15,[2]],
		"/(user)/user-home": [16,[3]],
		"/(user)/user-profile": [17,[3,4]],
		"/(user)/user-profile/account": [18,[3,4]],
		"/(user)/user-profile/appearance": [19,[3,4]],
		"/(user)/user-profile/overview": [20,[3,4]],
		"/(user)/user-profile/profile": [21,[3,4]],
		"/(user)/verify-email": [22,[3]],
		"/[community]": [23,[5],[6]],
		"/[community]/community/community-error": [25,[5,7],[6]],
		"/[community]/community/events": [26,[5,7],[6]],
		"/[community]/community/events/[uuid]": [27,[5,7],[6]],
		"/[community]/community/events/[uuid]/edit/[...paths]": [28,[5,7],[6]],
		"/[community]/community/forum/[forumSlug]/[uuid]": [29,[5,7,8],[6]],
		"/[community]/community/forum/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [30,[5,7,8],[6]],
		"/[community]/community/home": [31,[5,7],[6]],
		"/[community]/community/members": [32,[5,7],[6]],
		"/[community]/community/[slug]/[uuid]": [24,[5,7],[6]],
		"/[community]/content-library": [33,[5,9],[6]],
		"/[community]/doc": [34,[5,10],[6]],
		"/[community]/doc/[slug]/[uuid]": [35,[5,10],[6]],
		"/[community]/home": [36,[5],[6]],
		"/[community]/join": [37,[11]],
		"/[community]/join/[inviteCode]": [38,[11]],
		"/[community]/settings": [39,[5,12],[6]],
		"/[community]/settings/community/custom-roles": [40,[5,12],[6]],
		"/[community]/settings/community/labels": [41,[5,12],[6]],
		"/[community]/settings/community/manage-members": [42,[5,12],[6]],
		"/[community]/settings/community/settings": [43,[5,12],[6]],
		"/[community]/settings/content-library/settings": [44,[5,12],[6]],
		"/[community]/settings/general/custom-domain": [45,[5,12],[6]],
		"/[community]/settings/general/overview": [46,[5,12],[6]],
		"/[community]/settings/general/team": [47,[5,12],[6]],
		"/[community]/settings/general/webhooks": [48,[5,12],[6]],
		"/[community]/settings/knowledge-base/settings": [49,[5,12],[6]],
		"/[community]/workspace": [50,[5],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';